@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;500;700&display=swap'); */

.App {
  background-color: #fafafa;
  font-family: Arial, Helvetica, sans-serif;
  color: #4c4e55;
  /* text-align: center; */
  
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

#wip-logo {
  width: 85vw;
  display: none;
  opacity: 0.85;
}

#wip-logo-m {
  width: 60vw;
  opacity: 0.85;
}

.App-header {
  height: 10vh;
}

.top-nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.2em;
}

.top-nav-link {
  color: #b0b0b0
}

.top-nav-link:before {
  content: "";
  height: 0.5em;
  width: 0.5em;
  margin-right: 0.5em;
  display: inline-block;
}

.top-nav-link:hover:before {
  background-color: #b0b0b0;
}

.top-nav-link:after {
  content: "";
  height: 0.5em;
  width: 0.5em;
  margin-left: 0.5em;
  display: inline-block;
}

.top-nav-link:hover:after {
  background-color: #b0b0b0;
}

.top-nav-home {
  display: flex;
  align-items: center;
}

.top-nav-home-name {
  font-family: 'Source Code Pro', monospace;
  display: none;
}

.top-nav-logo {
  height: 6.5vh;
  margin: 0 0.5em;
}

.App-content {
  
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (min-width: 760px) {
  #wip-logo-m {
    display: none;
  }

  #wip-logo {
    display: inline;
    display: initial;
  }

  .top-nav-home-name {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  #wip-logo {
    width: 65vw
  }
}

